var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useReducer } from 'react';
import * as auth from 'external/domains/auth/passwordless/client';
import * as Sentry from 'shared/domains/sentry';
import AuthContext, { defaultContext } from './context';
import reducer, { ActionType } from './reducer';
function AuthProvider(props) {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, defaultContext);
    const authState = auth.state.get();
    useEffect(() => {
        function runAsync() {
            return __awaiter(this, void 0, void 0, function* () {
                dispatch({ type: ActionType.Loading });
                try {
                    yield auth.initSession();
                    dispatch({
                        type: ActionType.Response,
                    });
                }
                catch (error) {
                    Sentry.captureException(error);
                    dispatch({ type: ActionType.Error });
                }
            });
        }
        runAsync();
    }, []);
    const exposedValues = Object.assign({ data: authState }, state);
    return <AuthContext.Provider value={exposedValues}>{children}</AuthContext.Provider>;
}
export default AuthProvider;
