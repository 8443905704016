export var ActionType;
(function (ActionType) {
    ActionType["Loading"] = "LOADING";
    ActionType["Error"] = "ERROR";
    ActionType["Response"] = "RESPONSE";
})(ActionType || (ActionType = {}));
function authReducer(state, action) {
    if (!action) {
        return state;
    }
    const { type } = action;
    switch (type) {
        case ActionType.Loading:
            return { loading: true, error: false };
        case ActionType.Error:
            return { loading: false, error: true };
        case ActionType.Response:
            return {
                error: false,
                loading: false,
            };
        default:
            return state;
    }
}
export default authReducer;
