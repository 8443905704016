import { ApolloProvider } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { TetrisThemeProvider, StyleReset, ToastProvider, TooltipProvider } from '@pocketlaw/tetris';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import client from 'external/domains/apollo';
import * as auth from 'external/domains/auth/passwordless/client';
import AuthProvider from 'external/domains/auth/passwordless/components/Provider';
import { GlobalCSS } from 'external/domains/common-ui';
import { AppVersionAlert } from 'shared/domains/common-ui';
import { ErrorBoundary } from 'shared/domains/errors';
import { FeatureFlagSettings } from 'shared/domains/featureFlag';
import { dangerouslySetCurrentLocale, supportedLocales } from 'shared/domains/languages/config';
import { detectLocale } from 'shared/domains/languages/utils/detectLocale';
import * as Sentry from 'shared/domains/sentry';
import { GtmProvider } from 'shared/domains/tracking';
import Routes from './routes';
Sentry.init({
    application: 'external',
});
function App() {
    return (<TetrisThemeProvider>
      <StyleReset />
      <GlobalCSS />

      <I18nProvider i18n={i18n}>
        <ErrorBoundary>
          <GtmProvider>
            <AuthProvider>
              <ApolloProvider client={client}>
                <TooltipProvider>
                  <ToastProvider>
                    <BrowserRouter>
                      <Routes language={i18n.locale}/>
                      <AppVersionAlert />
                      <FeatureFlagSettings />
                    </BrowserRouter>
                  </ToastProvider>
                </TooltipProvider>
              </ApolloProvider>
            </AuthProvider>
          </GtmProvider>
        </ErrorBoundary>
      </I18nProvider>
    </TetrisThemeProvider>);
}
function render() {
    const container = document.getElementById('app-root');
    const root = createRoot(container);
    root.render(<App />);
}
auth
    .checkSession()
    .then(({ user }) => {
    const locale = detectLocale(supportedLocales, user === null || user === void 0 ? void 0 : user['https://pocketlaw.com/preferred_locale']);
    return dangerouslySetCurrentLocale(locale.code);
})
    .then(render);
