import { breakpoint } from '@pocketlaw/tetris/deprecated';
import { createGlobalStyle } from 'styled-components';
/* eslint-disable no-restricted-syntax */
export const GlobalCSS = createGlobalStyle `
  @media ${breakpoint.palm} {
    input, textarea {
    font-size: 16px!important;
    }
  }
`;
