import { createContext } from 'react';
export const defaultContext = {
    error: false,
    loading: true,
    data: {
        authenticated: false,
        credentials: null,
        user: null,
    },
};
const AuthContext = createContext(defaultContext);
export default AuthContext;
